@import '../Styles/variables.scss';

body{
    background: linear-gradient(to bottom right, $gradient-colors);
}

@media(min-width: $desktop){
    .feed_heading{
        width: 100vw;
        margin: auto;
        margin-top: 0;
        margin-bottom: 0;
        color: $offWhite;
        background-color: $primary-color;
        border-radius: 0px 0px 40px 40px;
        text-align: center;
        padding: 20px;
        box-shadow: $drop-shadow;

    }

    .feed_back_btn{
        margin-left: 50px;
        color: $secondary-color;
        background-color: $offWhite;
        padding: 10px;
        border-radius: 10px;
        border: solid 2px $offWhite;
        border-top-color: lighten($offWhite, 10%);
        margin-top: 10px;
        text-decoration: none;
        width: max-content;
        box-shadow: $drop-shadow;
    }

    .head-bar{
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 10px;


        .post{
            margin: auto;
            width: 500px;
            text-align: center;
            color: $offWhite;
            font-size: 20px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-around;

            background-color: rgba($color: #000000, $alpha: 0.2);
            padding-bottom: 50PX;
            border-radius: 5PX;

            #picture{
                margin: auto;
                margin-top: 30px;
                margin-bottom: 30px;

                padding: 10px;

                background-color: $accent-color;
                border-radius: 10px;
                #file-upload-button{
                    border-radius: 10px;
                }
            }

            .fileUploader{
                border-radius: 10px;
            }

            button{
                background-color: $secondary-color;
                padding: 10px;
                border: solid 2px $secondary-color;
                border-top-color: lighten($secondary-color, 10%);
                border-radius: 10px;
                width: 100px;
                color: $offWhite;
                font-size: larger;
                box-shadow: $drop-shadow;

            }
            button:hover{
                color:$accent-color;
            }
            button:active{
                box-shadow: $inner-shadow;
            }


        }
    }
    .user-prof-section{
        position: absolute;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        top: 100px;
        margin-right: 30px;
        color: $offWhite;
        font-size: 14px;

        .user_profile_picture{
            width: auto;
            height: 100px;
            clip-path: circle(40px at center);
            margin-top: 30px;
            margin-bottom: 10px;
        }
    }

    .card{
        margin: auto;
        margin-top: 30px;
        margin-bottom: 20px;
        width: 30vw;
        box-shadow: $drop-shadow;

        .feed_pic{
            height: 100%;
            width:100%;
            object-fit: cover;
            object-position: center;
            margin: auto;
            margin-top: 30px;
            padding: 10px;
            border-radius: 20px;
        }
        .post_sub_info{
            background-color: rgba($color: #000000, $alpha: 0.2);
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .post_date{
            padding-top: 10px;
            font-size: small;
        }

        .reaction_center{
            display: flex;

            .yum_reaction_btn{
                height: 30px;
                width: 30px;
                margin-top: 0;
                margin-left: 10px;
                margin-bottom: 10px;
            }
            .yum_reaction_btn:hover{
                cursor: pointer;
            }
            .yum_count{
                text-align: center;
                margin-left: 10px;
                margin-top: 5px;
            }
        }

    }

    .feed_log_prompt{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: rgba($color: #000000, $alpha: 0.2);
        margin: auto;
        width: 50vw;
        border-radius: 10px;
        padding: 10px;

        p{
            font-size: 24px;
            color: $offWhite;
        }
        .log-btn{
            transition: all 0.5s;
            padding:  10px 20px;
            border-radius: 10px;
            background-color: $secondary-color;
            color: $offWhite;
            text-decoration: none;
            border:  3px solid $primary-color;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }
        .log-btn:hover{
            background-color: $highlight-color;
            color: $secondary-color;
            border: 3px solid $secondary-color;
            text-shadow: 2px 2px 4px rgba(144, 134, 134, 0.5);
        }
    }
}

@media (min-width:$tablet) and (max-width: $desktop){
    .feed_heading{
        width: 100vw;
        margin: auto;
        margin-top: 0;
        margin-bottom: 0;
        color: $offWhite;
        background-color: $primary-color;
        border-radius: 0px 0px 40px 40px;
        text-align: center;
        padding: 20px;
        box-shadow: $drop-shadow;

    }

    .feed_back_btn{
        margin-left: 50px;
        color: $secondary-color;
        background-color: $offWhite;
        padding: 10px;
        border-radius: 10px;
        margin-top: 10px;
        text-decoration: none;
        width: max-content;
    }

    .head-bar{
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 10px;


        .post{
            margin: auto;
            width: 500px;
            text-align: center;
            color: $offWhite;
            font-size: 20px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-around;

            background-color: rgba($color: #000000, $alpha: 0.2);
            padding-bottom: 50PX;
            border-radius: 5PX;

            #picture{
                margin: auto;
                margin-top: 30px;
                margin-bottom: 30px;

                padding: 10px;

                background-color: $accent-color;
                border-radius: 10px;
                #file-upload-button{
                    border-radius: 10px;
                }
            }

            .fileUploader{
                border-radius: 10px;
            }

            button{
                background-color: $secondary-color;
                padding: 10px;
                border: none;
                border-radius: 10px;
                width: 100px;
                color: $offWhite;
                font-size: larger;
                box-shadow: $drop-shadow;
            }
            button:hover{
                color:$accent-color;
            }
            button:active{
                box-shadow: $inner-shadow;
            }


        }
    }
    .user-prof-section{
        position: absolute;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        top: 100px;
        margin-right: 30px;
        color: $offWhite;
        font-size: 14px;

        .user_profile_picture{
            background-color: $offWhite;
            width: auto;
            height: 100px;
            clip-path: circle(30% at center);
            margin-top: 30px;
            margin-bottom: 10px;
        }
    }

    .card{
        margin: auto;
        margin-top: 30px;
        margin-bottom: 20px;
        width: 30vw;
        box-shadow: $drop-shadow;
        .feed_pic{
            height: 500px;
            width:auto;
            margin: auto;
            margin-bottom: 30px;
            margin-top: 20px;
            border-radius: 10px;
        }
        .post_sub_info{
            background-color: rgba($color: #000000, $alpha: 0.2);
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .post_date{
            padding-top: 10px;
            font-size: small;
        }
    }

    .feed_log_prompt{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: rgba($color: #000000, $alpha: 0.2);
        margin: auto;
        width: 50vw;
        border-radius: 10px;
        padding: 10px;

        p{
            font-size: 24px;
            color: $offWhite;
        }
        .log-btn{
            transition: all 0.5s;
            padding:  10px 20px;
            border-radius: 10px;
            background-color: $secondary-color;
            color: $offWhite;
            text-decoration: none;
            border:  3px solid $primary-color;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }
        .log-btn:hover{
            background-color: $highlight-color;
            color: $secondary-color;
            border: 3px solid $secondary-color;
            text-shadow: 2px 2px 4px rgba(144, 134, 134, 0.5);
        }
    }

}
@media (min-width:$phone) and (max-width:$tablet){
    .feed_heading{
        width: 100vw;
        margin: auto;
        margin-top: 0;
        margin-bottom: 0;
        color: $offWhite;
        background-color: $primary-color;
        border-radius: 0px 0px 40px 40px;
        text-align: center;
        padding: 20px;
        box-shadow: $drop-shadow;

    }

    .feed_back_btn{
        margin-left: 20px;
        color: $secondary-color;
        background-color: $offWhite;
        padding: 10px;
        border-radius: 10px;
        margin-top: 20px;
        text-decoration: none;
        width: max-content;
    }

    .head-bar{
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 10px;


        .post{
            margin: auto;
            margin-top: 10vh;
            width: 500px;
            text-align: center;
            color: $offWhite;
            font-size: 20px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-around;

            background-color: rgba($color: #000000, $alpha: 0.2);
            padding-bottom: 50PX;
            border-radius: 5PX;

            #picture{
                margin: auto;
                margin-top: 30px;
                margin-bottom: 30px;

                padding: 10px;

                background-color: $accent-color;
                border-radius: 10px;
                #file-upload-button{
                    border-radius: 10px;
                }
            }

            .fileUploader{
                border-radius: 10px;
            }

            button{
                background-color: $secondary-color;
                padding: 10px;
                border: none;
                border-radius: 10px;
                width: 100px;
                color: $offWhite;
                font-size: larger;
                box-shadow: $drop-shadow;
            }
            button:hover{
                color:$accent-color;
            }
            button:active{
                box-shadow: $inner-shadow;
            }


        }
    }

    .user-prof-section{
        position: absolute;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        top: 7vh;
        color: $offWhite;
        font-size: 14px;

        .user_profile_picture{
            background-color: $offWhite;
            width: auto;
            height: 100px;
            clip-path: circle(30% at center);
            margin-top: 30px;
            margin-bottom: 10px;
        }
    }

    .card{
        margin: auto;
        margin-top: 30px;
        margin-bottom: 20px;
        width: 70vw;
        box-shadow: $drop-shadow;
        background-color: $secondary-color;
        .feed_pic{
            height: 75vw;
            width:auto;
            margin: auto;
            margin-bottom: 30px;
            margin-top: 20px;
            border-radius: 10px;
        }
        .post_sub_info{
            background-color: rgba($color: #000000, $alpha: 0.2);
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .post_date{
            padding-top: 10px;
            font-size: small;
        }
    }

    .feed_log_prompt{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: rgba($color: #000000, $alpha: 0.2);
        margin: auto;
        width: 50vw;
        border-radius: 10px;
        padding: 10px;

        p{
            font-size: 24px;
            color: $offWhite;
        }
        .log-btn{
            transition: all 0.5s;
            padding:  10px 20px;
            border-radius: 10px;
            background-color: $secondary-color;
            color: $offWhite;
            text-decoration: none;
            border:  3px solid $primary-color;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }
        .log-btn:hover{
            background-color: $highlight-color;
            color: $secondary-color;
            border: 3px solid $secondary-color;
            text-shadow: 2px 2px 4px rgba(144, 134, 134, 0.5);
        }
    }
}


@media(max-width: $phone){
    .feed_heading{
        width: 100vw;
        margin: auto;
        margin-top: 0;
        margin-bottom: 0;
        color: $offWhite;
        background-color: $primary-color;
        border-radius: 0px 0px 40px 40px;
        text-align: center;
        padding: 20px;
        box-shadow: $drop-shadow;

    }

    .feed_back_btn{
        margin-left: 20px;
        color: $secondary-color;
        background-color: $offWhite;
        padding: 10px;
        border-radius: 10px;
        margin-top: 20px;
        text-decoration: none;
        width: max-content;
    }

    .head-bar{
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 10px;


        .post{
            margin: auto;
            margin-top: 10vh;
            width: 90vw;
            text-align: center;
            color: $offWhite;
            font-size: 20px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-around;

            background-color: rgba($color: #000000, $alpha: 0.2);
            padding-bottom: 50PX;
            border-radius: 5PX;

            #picture{
                margin: auto;
                margin-top: 30px;
                margin-bottom: 30px;
                width: 80vw;

                padding: 10px;

                background-color: $accent-color;
                border-radius: 10px;

            }

            .fileUploader{
                border-radius: 10px;
            }

            button{
                background-color: $secondary-color;
                padding: 10px;
                border: none;
                border-radius: 10px;
                width: 100px;
                color: $offWhite;
                font-size: larger;
                box-shadow: $drop-shadow;
            }
            button:hover{
                color:$accent-color;
            }
            button:active{
                box-shadow: $inner-shadow;
            }


        }
    }

    .user-prof-section{
        position: absolute;
        right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        top: 11.5vh;
        color: $offWhite;
        font-size: 14px;

        .user_profile_picture{
            background-color: $offWhite;
            width: auto;
            height: 100px;
            clip-path: circle(40px at center);
            margin-top: 30px;
            margin-bottom: 10px;
        }
    }

    .card{
        margin: auto;
        margin-top: 30px;
        margin-bottom: 20px;
        width: 90vw;
        box-shadow: $drop-shadow;
        background-color: $secondary-color;
        .feed_pic{
            height: 100%;
            width:100%;
            object-fit: cover;
            object-position: center;
            margin: auto;
            margin-bottom: 30px;
            margin-top: 30px;
            padding: 10px;
            border-radius: 20px;
        }
        .post_sub_info{
            background-color: rgba($color: #000000, $alpha: 0.2);
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .post_date{
            padding-top: 10px;
            font-size: small;
        }
        .reaction_center{
            display: flex;

            .yum_reaction_btn{
                height: 30px;
                width: 30px;
                margin-top: 0;
                margin-left: 10px;
                margin-bottom: 10px;
            }
            .yum_reaction_btn:hover{
                cursor: pointer;
            }
            .yum_count{
                text-align: center;
                margin-left: 10px;
                margin-top: 5px;
            }
        }
    }

    .feed_log_prompt{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: rgba($color: #000000, $alpha: 0.2);
        margin: auto;
        width: 50vw;
        border-radius: 10px;
        padding: 10px;

        p{
            font-size: 24px;
            color: $offWhite;
        }
        .log-btn{
            transition: all 0.5s;
            padding:  10px 20px;
            border-radius: 10px;
            background-color: $secondary-color;
            color: $offWhite;
            text-decoration: none;
            border:  3px solid $primary-color;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }
        .log-btn:hover{
            background-color: $highlight-color;
            color: $secondary-color;
            border: 3px solid $secondary-color;
            text-shadow: 2px 2px 4px rgba(144, 134, 134, 0.5);
        }
    }
}
