@import "variables";

@media (max-width:$phone){
    .top{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        .logo{
            height: 100px;
            width: auto;
            margin-top: 30px;
        }
    
        h1{
            text-align: center;
            background-color: $primary-color;
            color: $offWhite;
            border-radius: 20px;
            width: 80vw;
            padding: 10px;
            margin: 20px auto;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }
        a{
            background-color: $secondary-color;
            border: solid 5px $primary-color;
            text-decoration: none;
            color: $offWhite;
            padding:  5px 10px;
            border-radius: 10px;
            margin-bottom: 10px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

            &:active{
                color: $highlight-color;
                font-weight: 600;
                border: solid 5px $offWhite;
            }
        }
        .food{
            width: 60vw;
            height: auto;
            border-radius: 10px;
            border: solid 5px $offWhite;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    
        }
    
        .list{
            background-color: $secondary-color;
            border-radius: 10px;
            font-weight: 600;
            padding: 20px;
            margin: 20px;
            border: solid 5px $offWhite;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }
        .directions{
            background-color: $secondary-color;
            width: 80vw;
            height: auto;
            border-radius: 10px;
            padding: 10px;
            border: solid  5px $offWhite;
            margin-bottom: 50px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    
            font-size: large;
            // font-weight: 400;
            line-height: 30px;
        }
    }

    .ad{
        width: 300px;
        height: 250px;
    }
    .comment{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $primary-color;
        width: 90vw;
        margin: auto;
        margin-bottom: 20px;
        padding: 20px;
        border-radius: 20px;
        border: 3px solid $offWhite;

        input{
            border-radius: 10px;
            border: none;
            width: 80vw;
            padding: 5px;
            margin-bottom: 10px;

        }

        button{
            width: 30vw;
            border-radius: 10px;
            background-color: $secondary-color;
            color: $offWhite;
            border: 3px solid $offWhite;
        }

        button:hover {
            background-color: $accent-color;
            color: $offWhite;
        }
    }

    .comment-section{
        .card{
            text-align: center;
            width: 90vw;
            background-color: $secondary-color;


            .card-body{
                font-size: large;
            }
            .card-footer{
                font-size: small;
            }
        }
    }


}

@media (min-width:$phone) and (max-width:$tablet){
    .top{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        .logo{
            height: 100px;
            width: auto;
            margin-top: 30px;
        }
    
        h1{
            text-align: center;
            background-color: $primary-color;
            color: $offWhite;
            border-radius: 20px;
            width: 80vw;
            padding: 10px;
            margin: 20px auto;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }
        a{
            background-color: $secondary-color;
            border: solid 5px $primary-color;
            text-decoration: none;
            color: $offWhite;
            padding:  5px 10px;
            border-radius: 10px;
            margin-bottom: 10px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

            &:active{
                color: $highlight-color;
                font-weight: 600;
                border: solid 5px $offWhite;
            }
        }
        .food{
            width: 60vw;
            height: auto;
            border-radius: 10px;
            border: solid 5px $offWhite;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    
        }
    
        .list{
            background-color: $secondary-color;
            border-radius: 10px;
            font-weight: 600;
            padding: 20px;
            margin: 20px;
            border: solid 5px $offWhite;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }
        .directions{
            background-color: $secondary-color;
            width: 80vw;
            height: auto;
            border-radius: 10px;
            padding: 10px;
            border: solid  5px $offWhite;
            margin-bottom: 50px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    
            font-size: large;
            // font-weight: 400;
            line-height: 30px;
        }
    }

    .ad{
        width: 300px;
        height: 250px;
    }
    .comment{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $primary-color;
        width: 90vw;
        margin: auto;
        margin-bottom: 20px;
        padding: 20px;
        border-radius: 20px;
        border: 3px solid $offWhite;

        input{
            border-radius: 10px;
            border: none;
            width: 80vw;
            padding: 5px;
            margin-bottom: 10px;

        }

        button{
            width: 30vw;
            border-radius: 10px;
            background-color: $secondary-color;
            color: $offWhite;
            border: 3px solid $offWhite;
        }

        button:hover {
            background-color: $accent-color;
            color: $offWhite;
        }
    }

    .comment-section{
        .card{
            text-align: center;
            width: 90vw;
            background-color: $secondary-color;


            .card-body{
                font-size: large;
            }
            .card-footer{
                font-size: small;
            }
        }
    }
}

@media (min-width:$tablet) and (max-width:$desktop) {
    .top{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        .logo{
            height: 100px;
            width: auto;
            margin-top: 30px;
        }
    
        h1{
            text-align: center;
            background-color: $primary-color;
            color: $offWhite;
            border-radius: 20px;
            width: 50vw;
            padding: 10px;
            margin: 20px auto;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }

        a{
            transition: border 1s;
            background-color: $secondary-color;
            border: solid 5px $primary-color;
            text-decoration: none;
            text-align: center;
            color: $offWhite;
            padding: 5px;
            width: 100px;
            border-radius: 5px;
            margin-bottom: 10px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

            &:active{
                color: $highlight-color;
                font-weight: 600;
                border: solid 5px $offWhite;
            }
        }

        .food{
            width: 40vw;
            height: auto;
            border-radius: 5px;
            border: solid 5px $offWhite;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    
        }
    
        .list{
            background-color: $secondary-color;
            border-radius: 5px;
            font-weight: 600;
            padding: 20px;
            margin: 20px;
            border: solid 5px $offWhite;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
            column-count: 2;
        }
        .directions{
            background-color: $secondary-color;
            width: 80vw;
            height: auto;
            border-radius: 5px;
            padding: 20px;
            border: solid  5px $offWhite;
            margin-bottom: 50px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    
            font-size: large;
            font-weight: 600;
            line-height: 50px;
        }
    }

    .ad{
        width: 300px;
        height: 250px;
    }
    .comment{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $primary-color;
        width: 60vw;
        margin: auto;
        margin-bottom: 20px;
        padding: 20px;
        border-radius: 20px;
        border: 3px solid $offWhite;

        input{
            border-radius: 10px;
            border: none;
            width: 50vw;
            padding: 5px;
            margin-bottom: 10px;

        }

        button{
            width: 10vw;
            border-radius: 10px;
            background-color: $secondary-color;
            color: $offWhite;
            border: 3px solid $offWhite;
        }

        button:hover {
            background-color: $accent-color;
            color: $offWhite;

        }
    }
    .card{
        text-align: center;
        width: 50vw;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: $secondary-color;
        // color: $offWhite;


        .card-body{
            font-size: large;
        }
        .card-footer{
            font-size: small;
        }
    }
}

@media (min-width:$desktop){
    .top{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        .logo{
            height: 100px;
            width: auto;
            margin-top: 30px;
        }
    
        h1{
            text-align: center;
            background-color: $primary-color;
            color: $offWhite;
            border-radius: 20px;
            width: 30vw;
            padding: 10px;
            margin: 20px auto;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }
        a{
            transition: border 1s;
            background-color: $secondary-color;
            border: solid 5px $primary-color;
            text-decoration: none;
            color: $offWhite;
            padding: 10px;
            border-radius: 5px;
            margin-bottom: 10px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }
        a:hover{
            color: $highlight-color;
            font-weight: 600;
            border: solid 5px $offWhite;
        }
        .food{
            width: 30vw;
            height: auto;
            border-radius: 5px;
            border: solid 5px $offWhite;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    
        }
    
        .list{
            background-color: $secondary-color;
            border-radius: 5px;
            font-weight: 600;
            padding: 20px;
            margin: 20px;
            border: solid 5px $offWhite;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
            column-count: 2;
        }
        .directions{
            background-color: $secondary-color;
            width: 50vw;
            height: auto;
            border-radius: 5px;
            padding: 20px;
            border: solid  5px $offWhite;
            margin-bottom: 50px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    
            font-size: large;
            font-weight: 600;
            line-height: 50px;
        }
    }

    .ad{
        width: 300px;
        height: 250px;
    }

    .card{
        text-align: center;
        width: 50vw;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: $secondary-color;
        // color: $offWhite;


        .card-body{
            font-size: large;
        }
        .card-footer{
            font-size: small;
        }
    }
    .comment{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $primary-color;
        width: 60vw;
        margin: auto;
        margin-bottom: 20px;
        padding: 20px;
        border-radius: 20px;
        border: 3px solid $offWhite;

        input{
            border-radius: 10px;
            border: none;
            width: 50vw;
            padding: 5px;
            margin-bottom: 10px;

        }

        button{
            width: 10vw;
            border-radius: 10px;
            background-color: $secondary-color;
            color: $offWhite;
            border: 3px solid $offWhite;
        }

        button:hover {
            background-color: $accent-color;
            color: $offWhite;

        }
    }
}

.print-btn{
    margin: auto;
    margin-top: 30px;
    background-color: $secondary-color;
    color: $offWhite;
    border: solid 3px $offWhite;
    border-radius: 20px;
}

@media print {
    body {
      font-size: 12pt;
      line-height: 1.4;
    }
    button {
      display: none;
    }
    a{
        display: none;
    }
    .comment-section{
        display: none;
    }
    .top{
        h1, h2, h3 {
            page-break-after: avoid;
            page-break-after: avoid;
            color: black;
            border-radius: 5px;
            font-weight: 600;
            padding: 20px;
            margin: 20px;
            border: solid 5px $primary-color;
            box-shadow: none;
            text-shadow: none;

        }
        .list{
            page-break-inside: avoid;
            border-radius: 5px;
            font-weight: 600;
            padding: 20px;
            margin: 20px;
            border: solid 5px $primary-color;
            width: 90vw;
            columns: 2;
            box-shadow: none;
            text-shadow: none;
        }

        .food{
            width: 30vw;
            height: auto;
            border: solid 5px $primary-color;
            box-shadow: none;
            text-shadow: none;
        }
        .directions{
            page-break-inside: avoid;
            border: solid 5px $primary-color;
            color: black;
            box-shadow: none;
            text-shadow: none;
            margin: 10px;
        }
    }


    .contact{
        display: none;
    }

    @page {
        size: auto;
        margin: 20px;
    }
}

