$primary-color:#4B6858;
$secondary-color:#9EADC8;
$highlight-color:#C6ECAE;
$accent-color:#7C606B;
$offWhite:#E8E8E8;

$primary-color-dark:darken($primary-color, 20%);
$secondary-color-dark:darken($secondary-color, 20%);
$highlight-color-dark:darken($highlight-color, 20%);
$accent-color-dark:darken($accent-color, 20%);

$gradient-colors:#ffafbd, #ffc3a0, #ffe09d, #c3e8bd, #b5ead7;

$drop-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
$text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
$inner-shadow: inset  0 0 5px 10px rgba(0, 0, 0, 0.1);

$darken-background:  rgba($color: #000000, $alpha: 0.2);

$phone: 480px;
$tablet: 768px;
$desktop: 1024px;