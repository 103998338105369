@import "variables";

@media(min-width: $desktop) {
    .sign-up{
        display: flex;
        justify-content: center;
        margin: 10px 20px;


        .log-btn{
            transition: all 0.5s;
            padding:  10px 20px;
            border-radius: 10px;
            background-color: $secondary-color;
            color: $offWhite;
            text-decoration: none;
            border:  3px solid $primary-color;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }

        .log-btn:hover{
            background-color: $highlight-color;
            color: $secondary-color;
            border: 3px solid $secondary-color;
            text-shadow: 2px 2px 4px rgba(144, 134, 134, 0.5);
        }

        p{
            color: $offWhite;
            margin: 10px;
        }

        .reset-password{
            transition: all 0.5s;
            background-color: $primary-color;
            margin: 10px;
            color: $offWhite;
            border-radius: 5px;
            border: solid 1px $offWhite;
        }

        .reset-password:active, .reset-password:hover{
            background-color: $highlight-color;
            color: black;
        }
    }
}

@media (min-width:$tablet) and (max-width: $desktop){
    .sign-up{
        display: flex;
        justify-content: center;
        margin: 10px 20px;


        .log-btn{
            transition: all 0.5s;
            padding:  10px 20px;
            border-radius: 10px;
            background-color: $secondary-color;
            color: $offWhite;
            text-decoration: none;
            border:  3px solid $primary-color;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }

        .log-btn:hover{
            background-color: $highlight-color;
            color: $secondary-color;
            border: 3px solid $secondary-color;
            text-shadow: 2px 2px 4px rgba(144, 134, 134, 0.5);
        }

        p{
            color: $offWhite;
            margin: 10px;
        }

        .reset-password{
            transition: all 0.5s;
            background-color: $primary-color;
            margin: 10px;
            color: $offWhite;
            border-radius: 5px;
            border: solid 1px $offWhite;
        }

        .reset-password:active, .reset-password:hover{
            background-color: $highlight-color;
            color: black;
        }
    }
}

@media (min-width:$phone) and (max-width:$tablet) {
    .sign-up{
        display: flex;
        justify-content: center;
        margin: 10px 20px;


        .log-btn{
            transition: all 0.5s;
            padding:  10px 20px;
            border-radius: 10px;
            background-color: $secondary-color;
            color: $offWhite;
            text-decoration: none;
            border:  3px solid $primary-color;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }

        .log-btn:hover{
            background-color: $highlight-color;
            color: $secondary-color;
            border: 3px solid $secondary-color;
            text-shadow: 2px 2px 4px rgba(144, 134, 134, 0.5);
        }

        p{
            color: $offWhite;
            margin: 10px;
        }

        .reset-password{
            transition: all 0.5s;
            background-color: $primary-color;
            margin: 10px;
            color: $offWhite;
            border-radius: 5px;
            border: solid 1px $offWhite;
        }

        .reset-password:active, .reset-password:hover{
            background-color: $highlight-color;
            color: black;
        }
    }
}

@media(max-width: $phone){
    .sign-up{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 20px;
        // width: 50vw;


        .log-btn{
            transition: all 0.5s;
            padding:  10px 20px;
            border-radius: 10px;
            width: 30vw;
            background-color: $secondary-color;
            color: $offWhite;
            text-decoration: none;
            text-align: center;
            border:  3px solid $primary-color;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }

        .log-btn:hover{
            background-color: $highlight-color;
            color: $secondary-color;
            border: 3px solid $secondary-color;
            text-shadow: 2px 2px 4px rgba(144, 134, 134, 0.5);
        }

        p{
            color: $offWhite;
            margin: 10px;
            text-align: center;
            width: 30vw;
            font-size: small;
        }

        .reset-password{
            transition: all 0.5s;
            background-color: $primary-color;
            margin: 10px;
            color: $offWhite;
            border-radius: 5px;
            border: solid 1px $offWhite;
            width: 30vw;
            font-size: small;
        }

        .reset-password:active, .reset-password:hover{
            background-color: $highlight-color;
            color: black;
        }
    }
}