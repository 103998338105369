@import './variables.scss';


.terms{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 700px;
    margin: auto;

    border: solid 5px $primary-color;
    background-color: $offWhite;

    text-align: center;

    li{
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.nav_home_btn{
    transition: all 0.5s;
    padding:  10px 20px;
    border-radius: 10px;
    background-color: $secondary-color;
    color: $offWhite;
    width: 100px;
    text-align: center;
    margin-left: 30px;
    margin-top: 30px;
    text-decoration: none;
    border:  3px solid $primary-color;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.nav_home_btn:hover{
    background-color: $highlight-color;
    color: $secondary-color;
    border: 3px solid $secondary-color;
    text-shadow: 2px 2px 4px rgba(144, 134, 134, 0.5);
}