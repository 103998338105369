@import "variables";

@media(min-width: $desktop) {
    .display{
        background-color: $secondary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-top: 0px;
        flex-wrap: wrap;
        width: 80vw;
        border: solid 5px $offWhite;
        border-radius: 0 0 10px 10px;
        margin-bottom: 30px;

        .plain{
            text-decoration: none;
            color: inherit;
        }

        .card{
            margin: 30px;
            max-width: 200px;
            height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: $offWhite;
            color: $accent-color;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

            .card-img{
                margin: 10px;
                width: 180px;
                height: auto;
            }
        }

    }

    .Head{
        margin-bottom: 50px;

        .nav-link:active{
            color: $highlight-color;
            transform: scale(1.1);
        }

        .plain{
            text-decoration: none;
            color: inherit;
        }
    }

    .sort-title{
        text-align: center;
        color: $offWhite;
        background-color: $accent-color;
        border-radius: 10px 10px 0 0;
        width: 80vw;
        margin: auto;
        margin-bottom: 0;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
}

@media(min-width: $tablet) and (max-width: $desktop) {
    .display{
        background-color: $secondary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-top: 0px;
        flex-wrap: wrap;
        width: 80vw;
        border: solid 5px $offWhite;
        border-radius: 0 0 10px 10px;
        margin-bottom: 30px;

        .plain{
            text-decoration: none;
            color: inherit;
        }

        .card{
            margin: 30px;
            max-width: 200px;
            height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: $offWhite;
            color: $accent-color;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

            .card-img{
                margin: 10px;
                width: 180px;
                height: auto;
            }
        }

    }

    .Head{
        margin-bottom: 50px;

        .nav-link:active{
            color: $highlight-color;
            transform: scale(1.1);
        }

        .plain{
            text-decoration: none;
            color: inherit;
        }
    }

    .sort-title{
        text-align: center;
        color: $offWhite;
        background-color: $accent-color;
        border-radius: 10px 10px 0 0;
        width: 80vw;
        margin: auto;
        margin-bottom: 0;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
}

@media(min-width: $phone) and (max-width: $tablet) {
    .display{
        background-color: $secondary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-top: 0px;
        flex-wrap: wrap;
        width: 80vw;
        border: solid 5px $offWhite;
        border-radius: 0 0 10px 10px;
        margin-bottom: 30px;

        .plain{
            text-decoration: none;
            color: inherit;
        }

        .card{
            margin: 30px;
            max-width: 200px;
            height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: $offWhite;
            color: $accent-color;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

            .card-img{
                margin: 10px;
                width: 180px;
                height: auto;
            }
        }

    }

    .Head{
        margin-bottom: 50px;

        .nav-link:active{
            color: $highlight-color;
            transform: scale(1.1);
        }

        .plain{
            text-decoration: none;
            color: inherit;
        }
    }

    .sort-title{
        text-align: center;
        color: $offWhite;
        background-color: $accent-color;
        border-radius: 10px 10px 0 0;
        width: 80vw;
        margin: auto;
        margin-bottom: 0;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
}

@media(max-width: $phone) {
    .display{
        background-color: $secondary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-top: 0px;
        flex-wrap: wrap;
        width: 90vw;
        border: solid 5px $offWhite;
        border-radius: 0 0 10px 10px;
        margin-bottom: 30px;

        .plain{
            text-decoration: none;
            color: inherit;
        }

        .card{
            margin: 20px;
            width: 250px;
            height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: $offWhite;
            color: $accent-color;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

            .card-img{
                margin: 10px;
                width: 200px;
                height: auto;
            }
        }
    }

    .Head{
        margin-bottom: 50px;

        .nav-link:active{
            color: $highlight-color;
            transform: scale(1.1);
        }

        .plain{
            text-decoration: none;
            color: inherit;
        }
    }

    .sort-title{
        text-align: center;
        color: $offWhite;
        background-color: $accent-color;
        border-radius: 10px 10px 0 0;
        width: 90vw;
        margin: auto;
        margin-bottom: 0;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
}